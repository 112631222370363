<template>
    <div class="menu-mobile">
        <div class="top">
            <img src="../../static/images/logo.png" >
            <img @click="close()" src="../../static/images/close.png" >
        </div>
        <div class="menu">
            <div @click="getMenu(item)" v-for="(item,index) in menuList" :key="index">
                <div :class="menuIndex==index?'get-menu':''">
                {{ $t('header.' + item) }}
                </div>
            </div>
        </div>
        <div style="height: 60px">

        </div>
        <div class="share">
            <img :src="require('../../static/images/' + item + '.png')"  v-for="(item,index) in shareList" :key="index">
        </div>
    </div>
</template>
<script setup>
    import { ref, reactive, onMounted, defineEmits } from 'vue';
    import { useRouter, useRoute } from 'vue-router';

    const menuIndex = ref(0)
    const menuList = reactive([
        'home','lp','award','solfi','user','ido'
    ])

    const router = useRouter();
    const getMenu = (item) =>{
        router.push({
            name: item
        })
    }

    onMounted(() => {
        getMenuIndex()
    })
    const getMenuIndex = () =>{
        const route = useRoute()
        const item = route.name;
        const index = menuList.indexOf(item);
        if(index != -1){
            menuIndex.value = index;
        }
        if(item === 'lprecord') menuIndex.value = 1
    }

    const shareList = reactive([
        'twitter','discord','gmail','github'
    ])

    const emits = defineEmits('close')
    const close = () => {
        emits('close')
    }
</script>
<style scoped>
    .menu-mobile{
        width: 100%;
        height: 100vh;
        z-index: 1003;
        position: fixed;
        top: 0;
        color: white;
        background: linear-gradient(90deg, #000000 0%, #1E313F 100%);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .menu-mobile .top{
        width: calc(100% - 20px);
        margin: 10px auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .menu-mobile .top img{
        /* width: 30px; */
        height: 30px;
    }
    .menu-mobile .top img:last-child{
        width: 30px;
        height: 30px;
    }
    .menu-mobile .menu{
        margin-left: 10px;
    }
    .menu-mobile .menu > div{
        width: calc(100% - 20px);
        height: 40px;
        white-space: nowrap;
        line-height: 40px;
        cursor: pointer;
        margin-bottom: 20px;
        margin-left: 20px;
        display: flex;
    }
    .menu-mobile .menu > div div{
        min-width: 80px;
        padding: 0 10px;
        text-align: center;
    }
    .menu-mobile .menu .get-menu{
        background-color: #8C66FC;
        border-radius: 100px;
    }
    .menu-mobile .share{
        width: calc(100% - 100px);
        margin-left: 50px;
        position: absolute;
        bottom: 30px;
        display: flex;
        justify-content: space-between;
    }
    .menu-mobile .share img{
        width: 30px;
    }
</style>
